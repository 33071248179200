var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "12" } }, [
            _c("h1", [_vm._v("Nieuws")])
          ]),
          _c(
            "b-col",
            { staticClass: "mt-4", attrs: { lg: "4", md: "6", cols: "12" } },
            [
              _c(
                "b-card",
                { attrs: { header: "Nieuwe functionaliteit" } },
                [_c("page-snippet", { attrs: { name: "new-functionality" } })],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "mt-4", attrs: { lg: "4", md: "6", cols: "12" } },
            [
              _c(
                "b-card",
                { attrs: { header: "Toekomstige functionaliteit" } },
                [_c("page-snippet", { attrs: { name: "roadmap" } })],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { staticClass: "mt-4", attrs: { lg: "4", md: "6", cols: "6" } },
            [
              _c("TrendList", {
                attrs: {
                  trendFilter: _vm.trendFilter,
                  perPage: 25,
                  aggName: "tracks",
                  title: "Top Tracks",
                  subTitle: "Streams afgelopen 7 dagen"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }